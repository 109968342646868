/* APPLE webkot corrections */
input {
    -webkit-appearance: none;
    text-align: left;
}

form {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    -webkit-appearance: none;
}

:root {
    --main-blue: #003066;
    --main-gray: #818582;
    --secondary-blue: #007fff;

    --gray-outline: rgba(0, 24, 51, 0.18);
}

.button {
    background-color: var(--main-blue);
    border: none;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 10px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.form_input {
    margin: 5px 5px 5px 0px;
    padding-right: 15px;
    box-sizing: border-box;
}

.form_input .label {
    white-space: nowrap;
    margin-left: 0;
    text-align: left;
}

.form_input input {
    width: 100%;
    /* border-radius: 5px; */
    background: #fff;
    outline: none;
    padding: 6px;
    margin: 0;
    font-size: large;
    border: 0px solid #ccc;
    border: 1px solid #fff;
    border-bottom: 1px solid #ccc;
    color: var(--main-blue);
}

/* APPLE corrections */
input::-webkit-date-and-time-value {
    color: var(--main-blue);
    text-align: left;
}

.form_input input:focus {
    border: 1px solid var(--secondary-blue);
    box-shadow: 1px 0px 3px 1px #c8def0;
}

.separator_horizontal {
    width: 100%;
    height: 1;
    background-color: var(--gray-outline);
}

.col-2 {
    float: left;
    display: inline;
    width: 50%;
    min-width: 100;
    box-sizing: border-box;
}

@media only screen and (max-width: 25em) {
    .col-2 {
        float: none;
        width: 100%;
        margin-bottom: 5px
    }
}



.collapse_content_container {
    max-width: 50px;
    overflow: hidden;
    transition: max-width 0.2s ease-out;
}

.collapse_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.filter_container {
    transition: all 1s;
}

.icon {
    height: 24px;
    aspect-ratio: auto;
    cursor: pointer;
}


/* Number Input without number arrows  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}