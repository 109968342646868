body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.labelClass {
  margin-top: -40px;
  background-color: rgb(255, 255, 255, 0.8);
  padding: 3px;
  border: solid 1px black;
  /* text-orientation:sideways; */
  /* writing-mode: vertical-rl; */
  text-align: center;

  /* orientation: "vertical"; */
  /* background-color: gray; */
}